import React, { Component } from 'react';

class CanvasComponent extends Component{
    componentDidMount() {
        this.updateCanvas();
    }
    updateCanvas() {
        // const ctx = this.refs.canvas.getContext('2d');
        // ctx.fillRect(0,0, 100, 100);

        const canvas = this.refs.canvas,
        ctx = this.refs.canvas.getContext('2d');

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        var stars = [], // Array that contains the stars
            FPS = 90, // Frames per second
            x = canvas.width > 500 ? 150 : 50, // Number of stars
            thumbImg,
            mouse = {
            x: 0,
            y: 0
            };  // mouse location

        // Push stars to array

        for (var i = 0; i < x; i++) {
            stars.push({
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height,
                radius: Math.random() * 1 + 1,
                vx: Math.floor(Math.random() * 50) - 25,
                vy: Math.floor(Math.random() * 50) - 25,
                type: 'circle'
            });
        }

        // Draw the scene

        function draw() {
        ctx.clearRect(0,0,canvas.width,canvas.height);
        
        ctx.globalCompositeOperation = "lighter";
        
        for (var i = 0, x = stars.length; i < x; i++) {
            var s = stars[i];
            if(s.type == 'circle'){
                ctx.fillStyle = "#fff";
                ctx.beginPath();
                ctx.arc(s.x, s.y, s.radius, 0, 2 * Math.PI);
                ctx.fill();
                ctx.fillStyle = 'black';
                ctx.stroke();
            }else{
                // ctx.fillStyle = "#c3d1ee";
                // ctx.beginPath();
                // ctx.arc(s.x, s.y, s.radius, 0, 2 * Math.PI);
                // ctx.fill();
                // ctx.fillStyle = 'black';
                // ctx.stroke();

                //tmpCtx.save();

                // ctx.fillStyle = "#fff";
                // ctx.beginPath();
                // ctx.arc(s.x, s.y, s.radius, 0, 2 * Math.PI);
                // ctx.fill();
                // ctx.drawImage(thumbImg, s.x-50, s.y-50, 100, 100);
                // ctx.beginPath();
                // ctx.arc(s.x, s.y, s.radius, 0, Math.PI * 2, true);
                // //ctx.fill();
                // ctx.fillStyle = 'black';
                // ctx.stroke();



                //ctx.save();
                ctx.beginPath();
                ctx.arc(s.x, s.y, s.radius, 0, Math.PI*2, true);   
                //tmpCtx.closePath();
                //ctx.clip();
                
                ctx.drawImage(thumbImg, s.x-25, s.y-25, 50, 50);
                
                ctx.beginPath();
                ctx.arc(s.x, s.y, s.radius, 0, Math.PI*2, true);
                //ctx.clip();
                //tmpCtx.closePath();
                //tmpCtx.restore();
            }
        }
        
        ctx.beginPath();
        for (var i = 0, x = stars.length; i < x; i++) {
            var starI = stars[i];
            ctx.moveTo(starI.x,starI.y); 
            if(distance(mouse, starI) < 150) ctx.lineTo(mouse.x, mouse.y);
            for (var j = 0, x = stars.length; j < x; j++) {
            var starII = stars[j];
            if(distance(starI, starII) < 150) {
                //ctx.globalAlpha = (1 / 150 * distance(starI, starII).toFixed(1));
                ctx.lineTo(starII.x,starII.y); 
            }
            }
        }
        ctx.lineWidth = 0.05;
        ctx.strokeStyle = 'white';
        ctx.stroke();
        }

        function distance( point1, point2 ){
        var xs = 0;
        var ys = 0;
        
        xs = point2.x - point1.x;
        xs = xs * xs;
        
        ys = point2.y - point1.y;
        ys = ys * ys;
        
        return Math.sqrt( xs + ys );
        }

        // Update star locations

        function update() {
        for (var i = 0, x = stars.length; i < x; i++) {
            var s = stars[i];
        
            s.x += s.vx / FPS;
            s.y += s.vy / FPS;
            
            if (s.x < 0 || s.x > canvas.width) s.vx = -s.vx;
            if (s.y < 0 || s.y > canvas.height) s.vy = -s.vy;
        }
        }

        canvas.addEventListener('mousemove', function(e){
            //console.log(e);
            mouse.x = e.clientX;
            mouse.y = e.clientY;
        });

        canvas.addEventListener('click', function(e){
            //console.log(e);
            mouse.x = e.clientX;
            mouse.y = e.clientY;
            stars.push({
                x: e.clientX,
                y: e.clientY,
                radius: Math.random() * 1 + 1,
                vx: Math.floor(Math.random() * 50) - 25,
                vy: Math.floor(Math.random() * 50) - 25,
                type: 'circle'
            });
        });

        // Update and draw

        function tick() {
            draw();
            update();
            requestAnimationFrame(tick);
        }

        tick();

        function addImageCircle(){
            thumbImg =  document.createElement('img');
            thumbImg.src = "https://nation.com.mx/wp-content/uploads/2018/12/La-historia-sin-fin.jpg";
            stars.push({
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height,
                radius: 50,
                vx: Math.floor(Math.random() * 50) - 25,
                vy: Math.floor(Math.random() * 50) - 25,
                type: 'other'
            });
        }

        var clicks = 0;
        var particlesContainer = document.querySelector('body');
        var lead = document.querySelector('.lead');
        var enableForm = (event) =>{
            clicks++;
            if( clicks > 6) lead.classList.remove('d-none');
        }
        particlesContainer.addEventListener("click", enableForm);
        var nodes = document.querySelectorAll('.menu-inner>a');
    // nodes.forEach((item) => item.addEventListener("click", navigate));
        //window.location.href = '#bio';

        var titles = [
            'Está naciendo',
            'It is being born',
            'Está nascendo',
            'Es wird geboren',
            'لقد ولد',
            'Այն ծնվում է',
            '它诞生了',
            'Это рождается',
            'それは生まれています',
            "C'est naître",
            "Està naixent"
        ]
        var i = 0;
        setInterval(
        function(){
            i++;
            if(i==titles.length){i=0}
            var titleElement = document.querySelector("h1.cover-heading");
            //titleElement.classList.add('breatheOut');
            titleElement.classList.add('breathe');
            titleElement.classList.add('breatheOut');
            setTimeout(()=>{
                titleElement.innerHTML = titles[i];
                titleElement.classList.remove('breatheOut');
            },3000);
        }, 9100);


    }
    render(){
        
           return (<div id="particles-container">
                <canvas ref="canvas" ></canvas>
            </div>)
        }
}

export default CanvasComponent;
