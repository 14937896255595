import React from 'react';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

import Modal from './components/ModalComponent';
import CanvasComponent from './components/CanvasComponent';

function App() {

  return (
    <div class="App">
        
        <div class="site-wrapper">
          <CanvasComponent />
          <div class="site-wrapper-inner">
            <div class="cover-container">
              <div class="masthead clearfix">
                <div class="inner">
                  <h3 class="masthead-brand"></h3>
                </div>
              </div><br />      
              <div class="inner cover">
                <h1 class="cover-heading">Está naciendo</h1>
    
                <p class="lead d-none"><button type="button" class="btn btn-lg btn-default btn-notify" data-toggle="modal" data-target="#subscribeModal">Notificarme</button></p>
              </div>
              <div class="mastfoot">
                <div class="inner">
                  <p></p>
                </div>
              </div>
            </div>
        </div>
        </div>
        <Modal />
      </div>
    
  );
}

export default App;
